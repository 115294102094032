
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.additional-comments {
  &__list {
    margin-top: 30px;
  max-width: 450px;
  }
  &__item {
    margin-bottom: 30px;
    .header {
      color: $graphite500;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 17px;
    }
    .paragraph {
      color: $graphite500;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

.additional-actions {
  margin-bottom: 20px;
  .header {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
  }
  .btn {
    margin-top: 20px;
    width: 100%;
  }
  .check-list {
    margin-top: 10px;
    .input__wrapper {
      margin-bottom: 10px;
    }
    .add-point {
      margin-top: 10px;
    }
  }
}
.additional-installation {
  &__list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__item {
    position: relative;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid $gray600;
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .delete {
      cursor: pointer;
      svg path {
        stroke: $graphite200;
      }
    }
  }
  &__click {
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(100% - 70px);
  }
  &__submit {
    margin-top: 0px !important;
  }
  &__form {
    margin-top: 50px;
  }
}

.additional-installation-preview {
  margin-top: 15px;
  padding: 4px 16px 4px 16px;
  background-color: $gray400;
  display: flex;
  flex-direction: column;
  &__right {
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    max-height: 19px;
    cursor: pointer;
    .svg {
      width: 18px;
      height: 18px;
      path {
        stroke: $graphite300;
      }
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  &__segment {
  padding: 12px 0;
    display: flex;
    flex-direction: column;
    &--top {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .line {
    display: flex;
    gap: 3px;
  }
  .paragraph {
    color: $gray700;
    font-size: 12px;
    font-weight: 400;
    line-height: 170%;
  }
  .header {
    color: $gray700;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  &--separator {
    border-bottom: 1px solid $gray600;
  }
  &__checkList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
  }
  &__checkList-item {
    background-color: #fff;
    padding: 10px 12px 10px 19px;
    border-radius: 3px;
    color: $graphite500;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}