
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.employee-rates-table {
  &__rate-wrapper {
    display: flex;
  }
  &__rate {
    text-align: left;
    width: 100%;
  }
  &__rate-change {
    color: $graphite400;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.12px;
    width: 100%;
    text-align: right;
  }
  &__mobile-rate {
    text-align: right;
    padding-right: 20px;
    width: 100%;
  }
  &__mobile-name {
    margin-left: 20px;
    text-align: left;
  }
}