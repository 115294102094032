
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.one-day-modal {
  z-index: 1000;
  border-radius: 4px;
  width: 340px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.20);
  &__content {
    padding: 12px 25px 26px 12px;
    position: relative
  }
  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: $gray400;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__day {
    color: $blue800;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
    margin-bottom: 10px;
    padding-right: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    &--initial {
      background-color: #F53B64;
      margin-top: 4px;
      padding: 0 6px;
      border-radius: 4px;
      .name {
        padding:  4.5px 0;
        line-height: 16px;
        color: #ffff;
      }
    }
  }
  &__item-hour {
    color: $blue800;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.12px;
  }
  &__item-name {
    color: $blue800;
    font-size: 12px;
    letter-spacing: -0.12px;
    line-height: 14px;
  }
  &__item-color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    flex-shrink: 0;
  }
}
