
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.cpp {
  background-color: #F5FBFF !important;
  opacity: 1 !important;
  border-radius: 6px !important;
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.05) !important; 
  padding: 0 !important;
  z-index: 9;
  &__wrapper {
    padding: 0 !important;
    position: relative;
    @include tablet {
      padding: 37px 25px 20px 25px !important;
    }
    width: 100%;
    
  }
  @include tablet {
    max-width: 340px !important;
    width: 100% !important;
    display: flex;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__top {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &__actions {
    display: flex;
    margin-left: auto;
    gap: 20px;
  }
  &__action {
    width: 20px;
    height: 20px;
    &:last-child {
      background-color: $gray400;
      border-radius: 50px;
    }
  }
  .react-tooltip-arrow {
    display: none;
  }

  &__event {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    @include tablet {
      margin-top: unset;
    }
  }
  &__event-name {
    white-space: normal;
    color: $blue800;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  &__event-date {
    color: $graphite300;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-bottom: 15px;
    text-transform: capitalize;
  }
  &__event-address {
    color: $blue800;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-bottom: 17px;
  }
  &__event-user {
    display: flex;
    align-items: flex-start;
  }
  &__event-user-box {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    flex-shrink: 0;
    margin-top: 2px;
  }
  &__event-user-name {
    color: $blue800;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;    
    margin-left: 10px;
    text-wrap: wrap;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    .btn {
      min-width: 120px;
    }
  }
  &__details {
    margin-top: 20px;
    color: #096EB5;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #096EB5;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
  }
}

.cpp-day {
  &__tooltip-open {
    z-index: 3;
  }
}