
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.follow-up-meeting {
  &__comments {
    margin-bottom: 16px !important;
  }
  .textarea__label {
    margin-left: 0;
  }
  .btn {
    margin-left: auto;
    margin-top: 15px;
  }
  .input__error {
    display: none;
  }
  .static-alert {
    margin-top: 16px;
    &--show {
      padding-right: 24px;
    }
  }
  &__actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-bottom: -20px;
    z-index: 9;
    position: relative;
  }
  &__action {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
    svg {
      width: 19px;
      height: 19px;
      path {
        stroke: $graphite300;
      }
    }
  }
  &__modal .modal__content{
    max-width: 600px;
  }
}