
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.scheduler-calendar {
 margin-top: 200px;
 margin-left: -20px;
 width: calc(100% + 40px);
 position: relative;
 @include tablet {
  margin-top: 40px;
    margin-left: 0;
    width: 100%;
    margin-bottom: 150px;
 }
 &__selects {
  position: absolute;
  display: flex;
  gap: 20px;
  z-index: 999;
  flex-direction: column;
  top: -190px;
  width: 100vw;
  padding: 0 20px;
  @include tablet {
    padding: unset;
    width: auto;
    right: 300px;
    top: -30px;
    flex-direction: row;
  }
  .select__wrapper, .multiple-select__wrapper {
    min-width: 248px;
    max-width: 100%;
    @include tablet {
      max-width: 248px;
    }
  }
 }
 .loader {
  min-height: 50vh;
  display: flex;
  div {
    margin: auto !important;
  }
 }
//  .calendar-mobile--day {
//   margin-top: 180px;
//  }
}



.scheduler-calendar .rbc-btn-group:first-child button{
  background-color: $white;
  &:first-child {
    display: none;
  }
}
.scheduler-calendar .rbc-btn-group:last-child button{
  &:last-child {
    display: none;
  }
}
