
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
@keyframes fadeInModal {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeOutModal {
  from { opacity: 1; }
  to { opacity: 0; }
}
@keyframes slideInRightModal {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideToRightModal {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes slideInBottomModal {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideToBottomModal {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
.modal-background {
  &:after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1000vw;
    margin-left: -100vw;
    margin-top: -100vh;
    height: 1000vh;
    background-color: rgba(0, 0, 0, 0.1); 
    z-index: 999;
  }
}
.modal {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 99999999;
  @include desktop {
    top: 0;
  }
  &--hidden {
     animation: fadeOutModal 0.2s ease-out forwards;
     transition: 0.3s;
  }
  &--center {
    visibility: visible; 
    opacity: 1;
    transform: scale(1);
    align-items: top;
    animation: slideInBottomModal 0.3s ease-out forwards;
    transition-delay: 0.1s; 
    z-index: 999999999999999999999999999999999999999999999;
    background: rgba(0, 0, 0, 0.10);
    transition: 0.3s;
    @include desktop {
      transition-delay: 0.1s; 
      padding-top: 150px;
      animation: fadeInModal 0.3s ease-out forwards;
    }
  }
  &--right {
    align-items: flex-end;
    animation: slideInBottomModal 0.3s ease-out forwards;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    justify-content: flex-end;
    @include desktop {
      align-items: center;
      animation: slideInRightModal 0.3s ease-out forwards;
    }
  }
  &__content {
    overflow: auto;
    z-index: 3;
    background-color: $white;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    &--position {
      z-index: 9;
      display: flex;
      align-items: flex-end;
      @include desktop {
        align-items: unset;
        display: unset;
      }
    }
    &--center {
      border-radius: 6px;
      display: block;
      width: 100vw;
      padding: 25px 20px 85px 20px;
      @include desktop {
        padding: 30px 50px 35px 50px;
        width: auto;
      }
    }
    &--right {
      width: 100vw;
      height: 90vh;
      padding: 25px 20px 84px 20px;
      overflow: auto;
      @include desktop {
        width: unset;
        padding: 50px 40px;
        height: 100vh;
      }
    }
    &--center-hidden {
      animation: slideToBottomModal 0.2s ease-out forwards;
      @include desktop {
        opacity: 0;
        transition: 0.3s;
        animation: unset;
      }
    }
    &--right-hidden {
      animation: slideToBottomModal 0.3s ease-out forwards;
      @include desktop {
        animation: slideToRightModal 0.3s ease-out forwards;
      }
    }
  }
  &--aside {
    right: 0;
    left: unset;
    width: unset;
  }
}

.modal-heading {
  color: $graphite500;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.18px;
  &__second-line {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    margin-top: 5px;
  }
}
.modal-sub-heading {
  color: $graphite500;
  font-size: 16px;
  font-weight: 600;
}

.modal-heading__with-close {
  display: flex;
  justify-content: space-between;
}
.modal-heading__close {
  color: $graphite300;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.modal-description {
  color:$graphite500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.14px;
  margin: 67px 0;
  span {
    font-weight: 700;
    margin-left: 3px;
  }
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 13px;
  .btn {
    min-width: 100px;
    width: 100%;
  }
}


.modal {

  &__separator {
    width: 100%;
    height: 1px;
    background-color: $graphite200;
    margin-top: 20px;
    @include desktop {
      margin-top: 10px;
    }
  }
  &__information-header {
    margin-top: 25px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      min-width: 105px;
      max-width: 105px;
    }

  }
  &__information-descript {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }
}

