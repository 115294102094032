
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.calendar-mobile {
  .rbc-day-slot {
    // pointer-events: none !important;
  }
    .rbc-event {
      border-bottom: 1px solid #fff !important;
    }
    .rbc-time-header {
      display: none;
    }
    .rbc-time-content {
      border-top: unset;
      padding-top: 20px;
      overflow: hidden;
      padding-right: 35px;
    }
    .rbc-time-view {
      background-color: #fff;
      padding: 30px 0 0 20px;
      border: unset;
    }
    .rbc-time-content > * + * > * {
      border: unset; 
    }
    .rbc-timeslot-group {
      border-bottom: unset;
    }
    .rbc-time-slot .rbc-label {
      color: $blue600;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      display: block;
      margin-top: -10px;
    }
    .rbc-time-column {
      margin-top: -10px;
    }
    .rbc-day-slot .rbc-time-slot {
      border-top: 1px solid $gray600;
    }
    &--day  {
      .rbc-time-view{
        padding-top: 14px;
      }
    }
    &--week {
      .rbc-event-label {
        display: none;
      }
    }
    .rbc-event {
      display: flex;
      flex-direction: column-reverse;
      .rbc-event-label {
        flex: 1 1;
      }
      // .rbc-event-content {
      //   flex: unset;
      // }
    }
    .rbc-event-content {
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: $blue800;
      font-size: 8px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: -0.08px; 
    }
    .rbc-event-label {
      color: $blue800;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.12px;
    }
    &--month, &--day {
      .rbc-event-content {
        font-size: 12px;
        flex: 0;
      }
    }
  &__active-day {
    background-color: transparent;
    padding: 0px 20px 0 24px;
    margin-bottom: -15px;
    z-index: 9;
    color: $graphite500;
    font-size: 14px;
    font-weight: 700;
  }

}

.calendar-sheet-event {
  &--measurer {
    border: unset !important;
    // background-color: #A5ECCB !important;
  }
}