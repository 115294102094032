
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.amp {
  &__modal {
    height: 100%;
    overflow: auto;
    // padding-bottom: 30px;
    @include tablet {
      height: 100vh;
    }
    .modal__content {
     @include desktop {
       min-width: 378px;
       max-width: 378px;
     }
   }
   .react-datepicker__month-container {
    padding: 0 20px 0 20px;
   }
   .react-datepicker__buttons {
    padding: 20px;
    gap: 10px;
   }
  }
  &__name {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
  }
  &__form {
    .textarea__label {
      margin-top: 10px;
    }
    .amp__hours .select__error span{
      position: absolute;
      padding-right: 20px;
      width: calc(100vw - 40px);
      @include tablet {
        width: 180px;
      }
    }
  }
  &__measurement-date {
    margin-top: 35px;
  }
  &__buttons {
    @include tablet {
      margin-top: 80px;
    }
  }
  &__hours {
    margin-top: 35px;
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
    .select__wrapper {
      width: 50%;
      @include tablet {
        width: auto;
      }
    }
    .select__error {
      position: relative;
    }
  }
  &__hours-separate {
    display: flex;
    align-items: center;
  }
}
.planned-visit {
  &__header {
    margin-bottom: 16px;
  }
  &__item {
    border-radius: 4px;
    background: $gray300;
    padding: 16px;
    margin-bottom: 8px;
    color: $gray700;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
}
.verified-list {
  display: flex;
  flex-direction: column;
  &__measurement {
    background-color: $gray100;
    padding: 16px;
    color: $gray700;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    border-radius: 4px;
    margin-top: 16px;
  }
  &__toFix {
    color: $gray700;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    padding: 16px;
    border-bottom: 1px solid $gray600;
    &:last-child {
      border-bottom: unset;
    }
  }
}