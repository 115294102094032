
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.installation-planning {
  border: 1px solid $gray600;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 14px 17px;
  max-width: 460px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    .checkbox-part-label {
      pointer-events: none;
    }
    &--more {
      margin-top: 10px;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  &__label {
    display: flex;
    font-size: 14px;
    line-height: 19px;
    color: $graphite500;
    align-items: center;
    max-width: 220px;
    span {
      flex-shrink: 0;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: $gray400;
      color: $blue500;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &__numbers {
    display: flex;
    gap: 15px;
  }
  &__number-item {
    display: flex;
    color: $graphite200;
    gap: 5px;
    .svg {
      height: 15px;
      width: 15px;
      path {
       stroke: $graphite200;
     }
    }
  }
  &__button {
    align-items: center;
    background-color: $blue500;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    .svg path{
      stroke: #fff;
    }
  }

  &__separator {
    height: 1px;
    width: 100%;
    background-color: $gray600;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;
  }
  &__delete-wrapper {
    text-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-bottom: 25px;
    width: fit-content;
    &--time-slotter {
      width: 100%;
      margin-top: 25px;
    }
  }
  &__delete-label {
    color: $graphite300;
    font-size: 14px;
    font-size: 500;
  }
  &__delete-icon {
    height: 18px;
    width: 18px;
  }
  &__time-slotter {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-bottom: 20px;

  }
  &__time-slotter-label {
    color: $blue500;
    font-size: 14px;
    font-weight: 500;
  }
  &__new-date {
    width: 100%;
  }
  &__separator {
    margin-top: 0 !important;
    margin-bottom: 15px;
    &--time-slotter {
      background-color: #CECECE;
    }
  }
  &__team {
    &--non-first-element {
      border-top: 2px solid #f2f2f2;
      margin-top: 25px;
    }

  }
  .error {
    font-size: 12px;
    font-weight: 600;
    color: $error;
  }
  &--checkbox {
    &:before {
      margin-right: 0 !important;
      width: 24px !important;
      height: 24px !important;
      border-radius: 4px !important;
    }
    &:after {
      width: 7px !important;
      height: 12px !important;
      left: 6px !important;
      margin-top: -4px !important;
    }
  }
  &--hover {
    cursor: pointer;
  }
  &--hidden {
    display: none;
  }
  &--comment {
    margin-top: 20px;
  }
  &__initial-date {
    font-size: 14px;
    color: $graphite200;
    font-weight: 600;
  }
}





