
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.photos-active-order {
  .active-order__bar {
    margin-bottom: 0;
  }
  .static-alert--show  {
    margin-bottom: 40px;
  }
  &__list {
    display: flex;
    gap: 8px;
  }
  &__item {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray400;
    border-radius: 4px;
    margin-bottom: 40px;
    border: 1px solid $gray400;
    &--order-preview {
      margin-bottom: 0;
    }
    &--active {
      cursor: pointer;
      @media(max-width:1024px) {
        background-color: #fff;
      }
    }
    &:hover {
      .failed-upload__actions {
        visibility: visible ;
      }
    }
    img {
      cursor: pointer;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__bar-heading {
    margin-top: 40px !important;
  }
  &--space-between {
    justify-content: space-between;
  }
}

.photos-modal {
  max-width: 809px;
  width: 100%;
  &__top {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  &__label {
    display: flex;
    align-items: center;
    color: $graphite500;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.18px;
  }
  &__previews {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 17px;
    position: relative;
    &--onclick {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  &__previews-item {
    max-width: calc((100% / 4) - 12px);
    max-height: 120px;
    width: 100%;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--delete {
      z-index: 9;
      position: absolute;
      top: -8px;
      right: -8px;
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      .svg path {
        transition: 0.2s;
        stroke: transparent;
      }
      &:hover {
        transition: 0.2s;
        transform: scale(1.1);
      }
    }
    &:hover {
      .photos-modal__previews-item--delete {
        background-color: #fff;
        transition: 0.2s;
        .svg path {
          transition: 0.2s;
          stroke: $graphite300;
        }
      }
    }

  }
  &__carousel, .slick-slider, .slick-list {
    max-height: 210px;
    @include tablet {
      max-height: 500px;
    }
  }
  &__carousel { 
    margin: 17px 0;
    .slick-arrow{
      z-index: 9;
      border-radius: 50px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background: rgba(255, 255, 255, 0.50);
      @include tablet {
        background-color: #fff;
        width: 48px;
        height: 48px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.50);
        @include tablet {
          background-color: #fff;
        }
      }
      &:before {
        display: none;
      }
      .svg path {
        stroke: #fff;
        @include desktop {
          stroke: $graphite300;
        }
      }
    }
    .custom-next-arrow { 
      right: 5px;
      @include desktop {
        right: -60px;
      }
    }
    .custom-prev-arrow { 
      left: 5px;
      @include desktop {
        left: -60px;
      }
    }
    .custom-next-arrow {
      transform: rotate(180deg);
    }
    .slick-item {
      position: relative;
      &:hover {
        .slick-item--delete {
          background-color: #fff;
          transition: 0.2s;
          &:hover {
            transform: scale(1.1);
          }
          .svg path {
            stroke: $graphite300;
          }
        }
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .slick-item--delete {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include desktop {

      }
      .svg path {
        stroke: transparent;
        transition: 0.2s;
      }
    }
    .slick-track {
      height: 100%;
    }
  }
}


.failed-upload {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray400;
  border-radius: 4px;
  cursor: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__actions {
    position: absolute;
    display: flex;
    @include desktop {
      visibility: hidden;
    }
    gap: 5px;
    top: -15px;
    right: -15px;
    svg {
      width: 16px;
    }
  }
  &__action-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(217, 217, 217, 0.5);
    border-radius: 4px;
  }
  &__center {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    transform: translateY(-50%, -50%);
  }
  &__center-item {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    svg {
      width: 24px;
    }
  }
  &__top {
    left: calc(50% - 12px);
    position: absolute;
    top: 2px;
    background-color: #fff;
    transform: translateY(-50%);
    border-radius: 20px;
  }
}

.photos-active-order:not(:first-child) {
  margin-bottom: 40px !important;
  .static-alert--show {
    margin-top: 40px;
  }
}
.photos-active-order .photos-active-order__item {
    margin-bottom: 0 !important;
    
}