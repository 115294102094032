
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.floating-menu {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.floating-menu::-webkit-scrollbar {
  display: none; 
}
.floating-menu-item {
  flex-shrink: 0;
  padding: 4px 20px;
  color: $graphite300;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.14px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 100px;
  &--active {
    transition: 0.2s;
    background-color: $graphite200;
    color: $white;
  }
}

.floating-menu-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;
  min-height: 55px;
  @include desktop {
    margin-right: 0;
  }
  .btn {
    display: block;
    min-width: 115px;
    margin-right: 20px;
    @include desktop {
      margin-right: unset;
      min-width: 200px;
    }
  }
  input {
    background-color: $white;
    width: 294px;
  }
  .input__error {
    display: none;
  }
  .right {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    .btn {
      margin: auto 0 0;
    }
    input {
      padding: 10px 21px;
      min-height: 40px;
      max-height: 40px;
    }
    .input__icon {
      top: 10px;
      right: 15px;
    }
  }
}

.floating-menu-with-search {
  min-height: 74px;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  padding-right: 20px;
  @include desktop {
    padding-right: 0;
    flex-direction: row;
    gap: 0;
  }
  .right {
    height: 66px;
    width: 100%;
    @include desktop {
      width: auto;
    }
    .input__wrapper {
      width: 100%;
    }
    input {
      width: 100%;
      padding-right: 40px;
    }
    &--hidden {
      display: none;
      @include tablet {
        display: flex;
      }
    }
  }
  .floating-menu {
    margin-right: auto;
  }
}