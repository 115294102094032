
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.verifications {
  &__bar-heading {
    margin-top: 24px !important;
    margin-bottom: 16px !important;
  }
  &__heading {
    margin-bottom: 0 !important;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item {
    background: #FAFAFA;
    padding: 20px;
    border-radius: 4px;
  }
  &__item-heading {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  &__form-item {
    padding: 16px 0;
    border-bottom: 1px solid #CECECE;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .switch {
      flex-shrink: 0;
      &__label {
        font-size: 14px;
        padding: 6px 23px 6px 23px;
        width: 100%;
        @include tablet {
          width: unset;
          padding: 6px 23px 6px 40px;
        }
      }
      &__name {
        color: $graphite500;
        font-size: 14px;
        font-weight: 400;
      }
      &__wrapper {
        flex-direction: column;
        gap: 24px;
        @include tablet {
          flex-direction: row;
        }
      }
    }
  }
  &__additional {
    padding: 20px 0;
    border-bottom: 1px solid #CECECE;
    &--first {
      padding-top: 0;
    }
    &--last {
      border: unset;
      padding-bottom: 0;
    }
    .switch {
      flex-shrink: 0;
    }
  }
}