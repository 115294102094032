
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.users-table {
  .user-status {
    padding-left: 5px;
    @include tablet {
      padding-left: unset;
    }
  }
  table {
    // border-collapse: separate;
    // border-spacing: 0 1px;
  }
  .rc-table-thead th{
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    text-align: left;
    padding: 5px 20px;
    @include tablet {
      background-color: $gray500;
      border-right: 1px solid $gray600;
    }
    &:first-child {
      font-weight: 600;
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-color: transparent;
    }
  }
  .rc-table-tbody {
    tr {
      // box-shadow: 0px 0px 2px 0px $gray600;
      border-radius: 6px !important;
      @include tablet {
        box-shadow: 0;
      }
    }
    td {
      background-color: $white;
      color: $graphite400;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
      margin-bottom: 1px;
      padding: 15px 0;
      @include tablet {
        padding: 15px 20px;
        // box-shadow: 0px 1px 0px 0px $gray600;
      }
      &:first-child {
        text-align: center;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        text-align: center;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .rc-table-cell-row-hover {
      @include tablet {
        background-color: $gray300;
      }
    }
    .rc-table-expanded-row-level-1  {
      transition: 0.3s;
      td {
        padding: 0;
      }
    }
  }
  .pagination {
    margin-top: 15px;
  }
}




.user-table-row {
  &__expand {
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    background-color: $white;
    padding-bottom: 15px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &__expand-top {
    text-align: left; 
    padding-left: 40px;
    padding-bottom: 15px;
  }
  &__expand-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
  }
  &__expand-item {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-right: 45px;
    cursor: pointer;
    &:last-child {
      margin-right: unset;
    }
  }
}


.users-table-mobile {
  &__name {
    text-align: left;
  }
  &__email {
    text-align: right;
    padding-right: 20px;
  }
}