
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.invoice-and-payment-methods {
  &__form {
    &__button {
      margin-top: 24px;
      margin-bottom: 50px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      svg path {
        stroke: $blue500;
        transition: 0.3s;
      }
      &:hover {
        transition: 0.3s;
        svg path {
          transition: 0.3s;
          stroke: #fff;
        }
      }
      .input__wrapper {
        width: 100%;
      }
    }
    .switch__wrapper  {
      margin-bottom: 16px;
    }
  } 
}